<template>
  <section class="containerResultsSearch">
    <resultsPage></resultsPage>
  </section>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import resultsPage from '@/components/resultsPage/resultsPage'

export default {
  name: 'results',
  components: {
    resultsPage
  },
  setup (props) {
    const store = useStore()

    onMounted(() => {
      store.commit({
        type: 'CHANGE_DEFAULT_FLOW',
        defaultSource: ''
      })
    })

    return {
      store
    }
  }
}
</script>
